:root {
    --debug-visible: none;
}

:link {
    text-decoration: underline;
}

@font-face {
    font-family: 'Archia';
    src: url('font/archia-regular-webfont.eot');
    src: url('font/archia-regular-webfont.eot?#iefix') format('embedded-opentype'),
        url('font/archia-regular-webfont.woff2') format('woff2'),
        url('font/archia-regular-webfont.woff') format('woff'),
        url('font/archia-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'archiasemibold';
    src: url('font/archia-semibold-webfont.eot');
    src: url('font/archia-semibold-webfont.eot?#iefix') format('embedded-opentype'),
        url('font/archia-semibold-webfont.woff2') format('woff2'),
        url('font/archia-semibold-webfont.woff') format('woff'),
        url('font/archia-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

.error-message {
    font-size: 0.9em;
    line-height: 1.2em;
    color: #f00;
    margin-left: 20px;
}

.debug_only {
    display: var(--debug-visible);
}

.speedmaster-login form {
    display: flex;
    flex-direction: row;
}

.speedmaster-login #submit-btn {
    margin-top: calc(1.2em + 20px);
}

.lost-password>div>span {
    white-space: pre;
}

.login_failed_msg {
    white-space: pre-line;
    display: inline-block;
}

html {
    box-sizing: border-box;
}

.password-security-container {
    margin-top: 3px;
}

*,
*:before,
.speedmaster-login *:after {
    box-sizing: inherit;
    font-family: Archia, Arial, 'Open Sans', sans-serif !important;
}

body {
    font-family: Archia, Arial, 'Open Sans', sans-serif !important;
    font-size: 100%;
    line-height: 1.125em;
    position: relative;
    color: #00233f;
    margin: 0;
}

header,
footer {
    background-color: #00233f;
    max-width: 1040px;
    margin: 0 auto;
    overflow: auto;
}

header {
    height: 80px;
}

footer {
    margin-top: 80px;
}

header:after,
footer:after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    background-color: #00233f;
    z-index: -1;
}

header:after {
    top: 0;
    height: 80px;
}

footer:after {
    bottom: 0;
    height: 225px;
}

.logo {
    margin: 20px 0 0 20px;
    text-indent: -9999px;
    height: 60px;
    width: 353px;
    background: url("img/login-speedmaster/logo.png") no-repeat;
    display: block;
}

.wrapper {
    background: url("img/login-speedmaster/header.jpg") no-repeat;
    background-position-x: 100%;
    background-position-y: 10%;
    height: 400px;
    background-size: cover;
}

.wrapper div,
.main {
    max-width: 1040px;
    margin: 0 auto;
}

div.img {
    max-width: 100%;
    padding: 330px 0 0 20px;
    background: url("img/login-speedmaster/label.png") no-repeat 20px bottom/376px;
    margin: 0;
}

h1 {
    font-size: 35px;
    border-bottom: 1px solid #ddd;
    line-height: 1.14;
    padding-left: 20px;
    padding-bottom: 19px;
    font-weight: 600;
    color: #e4002c;
    font-family: Archia, Arial, 'Open Sans', sans-serif !important;

}

h1:after {}

h2 {
    font-size: 24px;
    margin: 30px 20px 21px 20px;
    font-weight: 600;
    line-height: 1.17;
    color: #00233f;
}

p {
    /* margin: 0 20px; */
    font-size: 18px;
    opacity: 0.9;
    color: #00233f;
    line-height: 1.44;
}

p a {
    /* text-transform: uppercase; */
    text-decoration: none;
    color: rgba(0, 35, 62, 0.6);
}

form {}

input:not([type="checkbox"]) {
    height: 38px;
    width: 30%;
    max-width: 220px;
    margin-right: 15px;
    font-size: 1.125em;
    padding-left: 10px;
    border: 1px solid #434B4D;
    -webkit-appearance: none;
    border-radius: 0;
}

input[type=submit],
#submit-btn {
    width: 50px;
    border-radius: 0;
    border: none;
    background-color: #434B4D;
    color: #fff;
    display: inline-block;
    box-shadow: none;
}

.error {
    color: #e2001a;
    margin: 0;
}

b {
    font-weight: bold;
}

.custom-legend {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    padding: 5px 10px;
    border-style: solid;
    border-top-width: 1px;
    border-right-width: 0px;
    border-bottom-width: 1px;
    border-left-width: 0px;
    padding-top: 14px;
    padding-bottom: 14px;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 14px;
}

fieldset {
    border: none;
    padding: 0px;
}

address {
    margin: 60px 60px 60px 20px;
    color: #fff;
    font-size: 16px;
    float: left;
}

address ul {
    list-style: none;
}

address b {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
}

address ul,
address li {
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
    font-style: normal;
    font-size: 14px;
    opacity: 0.9;
    font-weight: 600;

}

address a {
    text-decoration: none;
    color: #fff;
}

address:nth-child(2) {
    margin-right: 0;
}

.login-speedmaster {
    width: 1040px;
    /* margin: 42px 20px 21px 20px; */
}

.cl-button-animated {
    cursor: pointer;
    transition: all .2s cubic-bezier(.175, .885, .32, 1.275);
    user-select: none;
}

.cl-button {
    position: relative;
    /* height: 35px; */
    padding-left: 45px;
    padding-right: 45px;
    /* margin: 5px; */
    text-align: center;
    font-size: 16px;
    line-height: 35px;
    background-color: #e4002c;
    border-radius: 4px;
    color: #fff;
    min-width: 230px;
}

.cl-button a {
    text-decoration: none;
    max-width: 379px;
}

.lost-password {
    max-width: 400px;
    margin: 0 auto;
}

/* Form Elements */
.login-speedmaster form div.parameter_input_field:first-of-type {
    /* margin-right: 5px; */
}

.parameter_input_field {
    margin-right: 16px;
    min-width: 379px;
    margin-bottom: 10px;
}

input.invalid {
    background-color: #ff00000c;
    /* background: linear-gradient(90deg, lightgray 40px, #fca6b6 40px); */
    border-color: #f00;
    border-radius: 3px;
}

.cl-input-header-text {
    position: relative;
    width: 100%;
    line-height: 18px;
    font-size: 12px;
    color: #00233f;
    font-weight: 600;
}

.cl-input-header {
    position: relative;
    width: 96%;
    padding-left: 2%;
    padding-right: 2%;
}

.cl-input-icon-no-icon-left {
    /* background: linear-gradient(90deg, lightgray 40px, white 40px); */
}

.cl-input-icon-email-left {
    /* background-image: url(img/sidemenu-icon-mail.svg);
    background-size: 22px;
    background-position: 8px;
    background-repeat: no-repeat; */
}

.full-width {
    width: 100% !important;
    max-width: none !important;
}

.link_list {
    margin-left: 20px;
    margin-top: 10px;
}

.link_list a {
    color: rgba(0, 35, 62, 0.6);
    display: inline;
    font-size: 14px;
    font-weight: 600;

}

.link_list a::after {
    display: block;
    content: ' ';
}

.link_list p {
    margin-left: 0px;
}

.form_errors {
    background-color: lightgray;
    border: thin solid gray;
    margin-top: 10px;
    margin-left: 20px;
    padding-top: 10px;
    margin-right: 20px;
}

.form_errors li {
    color: red;
}

.form-component {
    display: flex;
    width: 100%;
    max-width: 100%;
    margin-left: 20px;
    /* background-color: lightgray; */
}

.form-component>.cl-button {
    margin-top: 17.5px;
    height: 38px;
    /* border-radius: 0; */
    border: none;
    /* background-color: #434B4D; */
    color: #fff;
    display: inline-block;
    box-shadow: none;
}

.explanation {
    margin-left: 20px;
}

.registration {
    margin-left: 20px;
}

.support {
    margin-left: 20px;
    font-size: 18px;
    line-height: 1.44;
}

.moved-left {
    margin-left: 20px;
}

.account-data {
    flex-direction: column;
    width: 60%;
}

.moved-left .account-data div.parameter_input_field:first-of-type {
    margin-right: 0px;
}

/* /Form Elements */
@media only screen and (max-width: 480px) {
    input {
        display: block;
        width: 100%;
        margin: 0 0 20px 0;
        max-width: inherit;
    }

    address:nth-child(2) {
        margin-top: 0;
    }

    .wrapper {
        height: 200px;
        background-size: cover;
    }

    div img {
        padding-top: 140px;
        width: 50%;
    }
}

@media only screen and (max-width: 1024px) and (min-width: 481px) {
    .wrapper {
        height: 300px;
        background-size: cover;
    }
}